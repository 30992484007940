<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title>
        <span class="font-weight-bold">
          {{ $route.name }}
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">
          <v-icon>mdi-plus</v-icon>
          {{ $t("add") }} {{ meta.single }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-debounce:500ms="getItems"
              class="mr-1"
              v-model="filter.Name"
              :label="$t('bhth')"
              hide-details=""
              prepend-inner-icon="search"
              dense=""
              outlined=""
              filled=""
            />
          </v-col>
        </v-row>
        <v-data-table
          class="mytable"
          :items-per-page="$global.state.filter.pageSize"
          :items="$global.state.workflows"
          :loading="$global.state.loading"
          :headers="headers"
          hide-default-footer
          :loading-text="$t('loading')"
          :no-data-text="$t('no-data')"
          :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
        >
          <template v-slot:item.status="{}">
            <v-chip color="success">فعال</v-chip>
          </template>
          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                  <v-icon color="error"> delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('hthf') }}</span>
            </v-tooltip>

            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="openEditDialog(item)" v-on="on" icon>
                  <v-icon color="info"> edit </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="detials(item)" v-on="on" icon>
                  <v-icon color="primary"> mdi-eye </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('altfasyl') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Pagination />
    <Dialog />
  </div>
</template>

<script>
export default {
  components: {
    Dialog: () => import("./dialog"),
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("alism"),
          value: "name",
        },
        {
          text: this.$t('alwsf'),
          value: "description",
        },
        {
          text: this.$t('day'),
          value: "expireDays",
        },
        {
          text: this.$t('saah'),
          value: "expireHours",
        },
        {
          text: this.$t('alajraaat'),
          value: "actions",
        },
      ],
      filter: {
        Name: "",
      },
    };
  },
  methods: {
    detials(item) {
      this.$global.commit("setWorkflow", item);
      this.$router.push({
        path: "/workflow/" + item.id,
      });
    },
    getItems() {
      this.$global.dispatch(`get${this.meta.endPoint}`, this.filter);
    },
    async deleteItem(id, i) {
      await this.$service.deleteItem(
        id,
        `${this.$route.meta.endPoint}`,
        `delete`
      );
      await this.$eventBus.$once(`delete`, () => {
        setTimeout(() => {
          this.getItems();
        }, 10);
        // this.$global.state.workflows.splice(i, 1);
      });
    },

    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
  mounted() {
    this.getItems();
    console.log("fields mounted");
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },
};
</script>
